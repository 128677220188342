<template>
  <div class="manage">
    <div class="about-Img">
        <img src="../../assets/imgs/newsImg.png" alt="">
    </div>
    <div class="nav">
      <ul class="w">
        <li @click="addNum(0,'companyProfile')" :class="{ active: num === 'companyProfile' }">公司简介</li>
        <li @click="addNum(2,'news')" :class="{ active: num === 'news' }">新闻资讯</li>
        <li @click="addNum(1,'connect')" :class="{ active: num === 'connect' }">联系我们</li>
      </ul>
    </div>
    <div class="content">
      <div class="protect">
        <News v-if="!show" @details='details'></News>
        <Details :detailsData='detailsData' @ret='ret' v-else></Details>
      </div>
    </div>
  </div>
</template>

<script>
import News from "@/components/news";
import Details from "@/components/details";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      num: '',
      newsDetails:false,
      detailsData:{}
    };
  },
  created () {
    const newsData = this.$route.query.newsData
    if(newsData){
      this.detailsData = newsData
      this.newsDetails = true
      this.$store.commit('ip/SET_THOW',true)
    }
  },
  computed: {
    ...mapGetters(['show'])
  },
   mounted () {
      this.$store.commit('ip/SET_routefullPath',this.$route.fullPath)
      this.num = this.$route.query.num
  },
  destroyed (){
    this.newsDetails = false
    this.$store.commit('ip/SET_THOW',false)
  },
  methods: {
    addNum(i,val){
        this.num = val
        this.$router.push({name:val,query:{num:val}})
    },
    details(val,item){
        this.detailsData = item
        this.newsDetails = val
        this.$store.commit('ip/SET_THOW',val)
    },
    ret(){
      this.newsDetails = false
      this.$store.commit('ip/SET_THOW',false)
    }
  },
  components: {
    News,
    Details
  },
};
</script>

<style lang="scss" scoped>
.about-Img{
    height: 402px;
    img{
        width: 100%;
        height: 100%;
        vertical-align: bottom;
    }
}
.nav {
  height: 63px;
  line-height: 60px;
  background-color: #fbfbfb;
  border: solid 1px #ededed;
  margin-bottom: 10px;
  ul {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    li {
      min-width: 100px;
      font-size: 20px;
      color: #666666;
      box-sizing: border-box;
      border-bottom: 3px solid #fff;
      cursor: pointer;
    }
    li:nth-child(2) {
      margin: 0 50px;
    }
    .active {
      border-bottom-color:#e43838;
      color: #333333;
    }
  }
}
.content {
  .protect {
    margin-bottom: 10px;
  }
  .service {
    margin-bottom: 10px;
  }
  .manage {
    margin-bottom: 10px;
  }
}
</style>