<template>
  <div class="news">
    <div class="news-box w">
      <div class="news-box-left">
        <img src="../assets/imgs/news01.png" alt="" />
        <ul>
          <li @click="add('31')" :class="{styles:sty1}">
            <span>公司资讯</span>
            <em class="el-icon-arrow-right"></em>
          </li>
          <li @click="add('32')" :class="{styles:sty2}">
            <span>行业资讯</span>
            <em class="el-icon-arrow-right"></em>
          </li>
        </ul>
      </div>
      <div class="news-box-right">
        <div class="article" v-for="item of newsDataStat" :key="item.id" @click="details(item,newsDataStat)">
          <div class="article-left">
            <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
            <img v-else :src="defaultImage" alt="" />
          </div>
          <div class="article-right">
            <h4>{{ item.title }}</h4>
            <i>{{ item.publishTime | timeFiltes}}</i>
            <p v-html="item.columnsContent"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="btn">
      <span @click="moreData"><em class="el-icon-arrow-down"></em> 查看更多</span>
    </div>
  </div>
</template>

<script>
  import { getColumnContent } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
        newsData: [],
        newsDataStat: [],
        sty1: null,
        sty2: null
      };
    },
    created() {
      // 展览展示数据 31公司资讯 32行业资讯
      getColumnContent({ levelType: "31" }).then((res) => {
        if (res.code === 0) {
          this.newsData = res.data.content;
          this.newsDataStat = this.newsData.slice(0, 5);
        }
      });
    },
    methods: {
      details(item, newsDataStat) {
        this.$emit("details", true, item, newsDataStat);
      },
      add(val) {
        if (val === "31") {
          getColumnContent({ levelType: "31" }).then((res) => {
            if (res.code === 0) {
              this.newsData = res.data.content;
              this.newsDataStat = this.newsData.slice(0, 5);
              this.sty1 = true
              this.sty2 = null
            }
          });
        } else {
          getColumnContent({ levelType: "32" }).then((res) => {
            if (res.code === 0) {
              this.newsData = res.data.content;
              this.newsDataStat = this.newsData.slice(0, 5);
              this.sty1 = null
              this.sty2 = true
            }
          });
        }
      },
      moreData() {
        const length = this.newsData.length;
        if (length > this.newsDataStat.length) {
          this.newsDataStat = this.newsData.slice(
            0,
            this.newsDataStat.length + 2
          );
        } else {
          this.$message({
            type: "warning",
            message: "没有更多数据了",
          });
        }
      },
    },
    filters: {
      timeFiltes(val) {
        const starting = val.substr(0, 10)
        return starting
      },
    },
  };
</script>

<style lang="scss" scoped>
  .news-box {
    margin-top: 20px;
    display: flex;
    justify-content: left;
    .news-box-left {
      width: 259px;
      height: 100%;
      margin-right: 36px;
      img {
        display: block;
        width: 259px;
        height: 141px;
        margin-bottom: 10px;
      }
      ul {
        li {
          height: 60px;
          line-height: 60px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #c6c6c6;
          padding: 0 10px;
          span {
            color: #666666;
            font-size: 20px;
          }
          em {
            color: #666666;
            font-size: 20px;
          }
        }
        .styles {
          border-bottom: 1px solid #e43838;
          span {
            color: #e43838;
            font-size: 20px;
          }
          em {
            color: #e43838;
            font-size: 20px;
          }
        }
        li:hover {
          border-bottom: 1px solid #e43838;

          span {
            color: #e43838;
            font-size: 20px;
          }
          em {
            color: #e43838;
            font-size: 20px;
          }
        }
      }
    }
    .news-box-right {
      width: 1100px;
      height: 100%;
      .article {
        height: 255px;
        margin-bottom: 10px;
        display: flex;
        justify-content: left;
        align-items: center;
        .article-left {
          width: 354px;
          height: 190px;
          cursor: pointer;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.9s;
          }
          img:hover {
            transform: scale(1.3);
          }
        }
        .article-right {
          width: 100%;
          height: 204px;
          padding-left: 20px;
          cursor: pointer;
          h4 {
            width: 750px;
            height: 30px;
            line-height: 30px;
            text-align: left;
            font-size: 24px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          i {
            font-style: normal;
            display: block;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            width: 100%;
            text-align: left;
            color: #919797;
            font-family: '黑体';
            // margin: 7px 0;
          }
          p {
            width: 750px;
            line-height: 30px;
            text-align: left;
            font-size: 18px;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            font-family: '微软雅黑';
            margin-top: 20px;
          }
        }
      }
    }
  }
  .btn {
    span {
      display: inline-block;
      width: 150px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      font-size: 20px;
      color: #999999;
      em {
        margin-right: 10px;
      }
    }
  }
</style>