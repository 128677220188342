<template>
  <div class="details-box">
    <div class="details" v-if="detailsDataText.articleContent">
      <div class="title w">
        <div class="title-box">{{ detailsDataText.title }}</div>
        <div class="text-type">
          <em>时间:{{ detailsDataText.publishTime }}</em>
        </div>
        <div class="article-box">
          <div class="video-component w">
            <video-player
              v-if="detailsDataText.columnsContentFiles[0] && Optionssources"
              class="video-player vjs-custom-skin size-box"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            />
          </div>
          <div class="article" v-html="textcontent"></div>
          <div class="ret-box">
            <span @click="ret">返回</span>
            <span>分享</span>
            <span @click="shareToQQ"><i class="iconfont icon-qq3"></i></span>
            <span @click="shareToMicroblog"
              ><i class="iconfont icon-weibo2"></i
            ></span>
          </div>
        </div>
        <div class="down">
          <div
            class="previous"
            v-if="articles.lastName"
            @click="getdown(articles.lastId)"
          >
            上一篇：<span>{{ articles.lastName }}</span>
          </div>
          <div class="previous" v-else>上一篇：<span>无</span></div>
          <div
            class="next"
            v-if="articles.nextName"
            @click="getdown2(articles.nextId)"
          >
            下一篇：<span>{{ articles.nextName }}</span>
          </div>
          <div class="next" v-else>下一篇：<span>无</span></div>
        </div>
      </div>
    </div>
    <div v-else class="noDate w">暂无数据</div>
  </div>
</template>

<script>
import { getdown } from "../api/index";
import { mapGetters } from "vuex";
export default {
  props: {
    detailsData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      baseIP: process.env.VUE_APP_BASE_URL,
      imageShowAPI: "/yuan/files/getFileStream/",
      articles: {},
      detailsDataText: {},
      detailsid: "",
      textcontent: "",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4", // url地址  http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4
          },
        ],
        poster: "", // 你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
      Optionssources:''
    };
  },
  computed: {
    ...mapGetters(["id"]),
  },
  mounted() {
    if (this.detailsData) {
      // this.detailsDataText = this.detailsData;
      this.detailsid = this.detailsData;
    }
    // this.detailsData.id
    // this.$store.commit('ip/SET_ID',this.detailsData.id)
    // const ids = localStorage.getItem('id')
    // 先存
    if (this.detailsData.id) {
      localStorage.setItem("id", this.detailsData.id);
    }
    this.detailsid = localStorage.getItem("id");
    this.getdownData(this.detailsid);
    // this.getdownData(this.id);
  },
  methods: {
    //分享到QQ
    shareToQQ() {
      let title = this.detailsDataText.title;
      let _url = "http://www.yuanit.com.cn/"; //自定义网站链接地址
      let url = window.location.href; //获取当前网页地址
      var _shareUrl = "https://connect.qq.com/widget/shareqq/index.html?";
      _shareUrl += "url=" + encodeURIComponent(_url || url);
      _shareUrl += "&title=" + encodeURIComponent(title);
      window.open(_shareUrl, "_blank"); //qq
    },
    //分享到微博
    shareToMicroblog() {
      let title = this.detailsDataText.title;
      let url = window.location.href; //获取当前网页地址
      let content = this.detailsDataText.columnsContent; //
      let sharesinastring1 =
        "https://service.weibo.com/share/share.php?title=" +
        encodeURIComponent(content + "「" + title + "」" + " 点这里" + url);
      window.open(sharesinastring1, "_blank"); //微博在新页面打开
    },
    // 分享到微信
    shareWeixin() {
      this.$message.warning("暂时无法分享");
    },
    getdown(val) {
      this.getdownData(val);
      localStorage.setItem("id", val);
    },
    getdown2(val) {
      this.getdownData(val);
      localStorage.setItem("id", val);
    },
    getdownData(val) {
      getdown({ id: val }).then((res) => {
        console.log(res, "文章");
        if (res.code === 0) {
          this.articles = res.data;
          this.detailsDataText = res.data;
          this.textcontent = this.detailsDataText.articleContent.replace(
            /<img/g,
            "<img style='max-width:100%;height:auto;'"
          );
          console.log(this.detailsDataText,'this.detailsDataText')
          if (res.code === 0 && res.data.columnsContentFiles.length>0) {
            this.playerOptions.sources[0].src = "";
            this.playerOptions.sources[0].src =
              this.baseIP +
              this.imageShowAPI +
              res.data.columnsContentFiles[0].files.uri;
            this.playerOptions.poster =
              this.baseIP + this.imageShowAPI + res.data.imageUri;
              this.Optionssources = this.baseIP + res.data.columnsContentFiles[0].files.uri;
              console.log(this.Optionssources,'this.Optionssources')
          }
        }
      });
    },
    ret() {
      this.$emit("ret");
    },
  },
};
</script>

<style lang="scss" scoped>
.video-component {
  width: 800px;
  // height: 800px;
  // line-height: 749px;
  margin-top: 24px;
  .size-box {
    width: 800px;
    // height: 800px;
  }
}
/deep/ .video-js {
  background-color: transparent;
}
/deep/ .vjs-poster {
  background-size: cover;
}
.details {
  margin-top: 20px;
  .ret {
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 20px;
    cursor: pointer;
    em {
      font-style: normal;
      cursor: pointer;
    }
    em:hover {
      color: #e43838;
    }
  }
  .title {
    .title-box {
      width: 1400px;
      line-height: 40px;
      text-align: center;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
      margin-top: 60px;
      font-size: 30px;
      font-weight: 600;
    }
    .text-type {
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-bottom: 1px dashed #ddd;
      padding-bottom: 3px;
      em {
        font-style: normal;
        color: #999999;
        display: inline-block;
        padding: 10px 0 5px 0;
      }
    }
    .article-box {
      position: relative;
      .article {
        margin-top: 50px;
        line-height: 40px;
        text-align: left;
        margin-bottom: 10px;
        font-size: 22px;
        font-family: "微软雅黑";
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 20px;
      }
      .ret-box {
        width: 60px;
        background-color: #fff;
        box-shadow: 0px 0px 20px rgba(56, 56, 56, 0.13);
        position: fixed;
        right: 200px;
        top: 50%;
        z-index: 200;
        span {
          display: block;
          width: 60px;
          height: 60px;
          line-height: 60px;
          margin: 10px 0;
          font-size: 18px;
          cursor: pointer;
          font-family: "微软雅黑";
          i {
            font-size: 30px;
          }
        }
        span:hover {
          color: #e43838;
        }
      }
    }

    .down {
      height: 80px;
      line-height: 80px;
      margin-bottom: 70px;
      .previous {
        height: 40px;
        line-height: 40px;
        text-align: left;
        font-size: 18px;
        span {
          cursor: pointer;
        }
        span:hover {
          color: #e43838;
        }
      }
      .next {
        height: 40px;
        line-height: 40px;
        text-align: left;
        font-size: 18px;
        span {
          cursor: pointer;
        }
        span:hover {
          color: #e43838;
        }
      }
    }
  }
}
.noDate {
  text-align: center;
  font-size: 40px;
  color: #999999;
}
</style>